import React, { useState } from "react"
import PropTypes from "prop-types"
import pluralize from "pluralize"
// import { useSelector } from "react-redux"

// import { selectCurrentUser } from "@selectors"
import { useTranslation } from "@hooks"

// import CommentsList from "./CommentsList"
// import CommentsForm from "./CommentsForm"

import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Comments = ({ photo }) => {
  const [t] = useTranslation()
  const [count] = useState(photo.commentsCount || 0)
  // const currentUser = useSelector(selectCurrentUser)
  return (
    <>
      <Content>
        <Heading subtitle size={5}>
          {count} {pluralize(t("components.Comments.heading"), count)}
        </Heading>
      </Content>
      {/* {currentUser && <CommentsForm photo={photo} user={currentUser} />}
      <CommentsList photo={photo} /> */}
    </>
  )
}

Comments.defaultProps = {
  photo: {},
}

Comments.propTypes = {
  photo: PropTypes.object.isRequired,
}

export default Comments

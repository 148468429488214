import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@lib/fontAwesome"

const Location = ({ location, icon, size, color }) => {
  if (!location) return null
  return (
    <p className={"is-valigned"}>
      <FontAwesomeIcon
        icon={icon}
        size={size}
        color={color}
        style={{ marginRight: 15 }}
      />{" "}
      {location}
    </p>
  )
}

Location.defaultProps = {
  size: "2x",
  color: "#1c1c1e",
  icon: "map-marker-alt",
}

Location.propTypes = {
  size: PropTypes.oneOf([
    "lg",
    "xs",
    "sm",
    "1x",
    "2x",
    "3x",
    "4x",
    "5x",
    "6x",
    "7x",
    "8x",
    "9x",
    "10x",
  ]).isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  location: PropTypes.string,
}

export default Location

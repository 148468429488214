import React from "react"
import PropTypes from "prop-types"
import {
  // InstantSearch,
  connectHits,
  // Configure
} from "react-instantsearch-dom"
import pluralize from "pluralize"

import User from "@components/User"
import NoResults from "@components/NoResults"

import {
  // useAlgolia,
  useTranslation,
} from "@hooks"

import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Hits = ({ hits }) => {
  return (
    <Columns breakpoint="mobile" gapless>
      {!hits.length ? (
        <NoResults />
      ) : (
        hits.map((hit, i) => <User key={i} user={hit} />)
      )}
    </Columns>
  )
}

const CustomHits = connectHits(Hits)

CustomHits.defaultProps = {
  hits: [],
}

CustomHits.propTypes = {
  hits: PropTypes.array.isRequired,
}

const Likes = ({ photo }) => {
  const [t] = useTranslation()
  // const searchClient = useAlgolia()

  const photoLikesCount = photo && photo.likes ? photo.likes.length : 0

  if (!photo) return null
  return (
    <>
      <Content>
        <Heading subtitle size={5}>
          {photoLikesCount}{" "}
          {pluralize(t("components.Likes.heading"), photoLikesCount)}
        </Heading>
      </Content>
      {/* <InstantSearch indexName={"users"} searchClient={searchClient}>
        <Configure filters={`likes:${photo.id}`} hitsPerPage={9} />
        <CustomHits />
      </InstantSearch> */}
    </>
  )
}

Likes.propTypes = {
  user: PropTypes.array,
  filters: PropTypes.string,
}

export default Likes

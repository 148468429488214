import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.scss"

const Caption = ({ caption }) => {
  if (!caption) return null
  return <p className={styles.Caption}>{caption}</p>
}

Caption.propTypes = {
  caption: PropTypes.string,
}

export default Caption

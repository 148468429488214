import React from "react"
import { InstantSearch, Configure, connectHits } from "react-instantsearch-dom"
// import { navigate } from "gatsby"

// import * as R from "@constants/routes"
import { INDEX_PHOTOS } from "@constants"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import Styles from "@components/Styles"
import Location from "@components/Location"
import CreatedAt from "@components/CreatedAt"
import Caption from "@components/Caption"
import User from "@components/User"
import Flash from "@components/Flash"
import Portfolio from "@components/Portfolio"
import Comments from "@components/Comments"
import Likes from "@components/Likes"
import Photo from "@components/Photo"
// import Share from "@components/Share"
// import Comment from "@components/CommentButton"
// import ButtonLike from "@components/ButtonLike"
import { useAlgolia, useTranslation } from "@hooks"

import Container from "react-bulma-components/lib/components/container"
import Columns from "react-bulma-components/lib/components/columns"
import Box from "react-bulma-components/lib/components/box"
import Section from "react-bulma-components/lib/components/section"

const PhotoComponent = ({ hits }) => {
  const [t] = useTranslation()

  const photo = hits[0]

  if (!photo) {
    // if (typeof window !== "undefined") {
    //   return navigate(R.NOT_FOUND)
    // }
    return null
  }

  return (
    <>
      <SEO
        title={`${t("pages.Tattoo.seo.title")} - ${photo.id}`}
        description={photo.caption || t("pages.Tattoo.seo.description")}
      />
      <Columns breakpoint="mobile">
        <Columns.Column
          mobile={{
            size: 12,
          }}
          tablet={{
            size: 12,
          }}
          desktop={{
            size: 8,
          }}
          widescreen={{
            size: 8,
          }}
          fullhd={{
            size: 8,
          }}
        >
          <Photo photo={photo} />
          <Box>
            <User user={photo.user} shadowless marginless paddingless />
            <Caption caption={photo.caption} />
            <CreatedAt createdAt={photo.createdAt} />
            <Location location={photo.location} />
          </Box>

          {/* <Share />
        <Comment />
        <ButtonLike /> */}
          <Flash user={photo.user} filters={` AND NOT objectID:${photo.id}`} />
          <Portfolio
            user={photo.user}
            filters={` AND NOT objectID:${photo.id}`}
          />
        </Columns.Column>
        <Columns.Column
          mobile={{
            size: 12,
          }}
          tablet={{
            size: 12,
          }}
          desktop={{
            size: 4,
          }}
          widescreen={{
            size: 4,
          }}
          fullhd={{
            size: 4,
          }}
        >
          <Box>
            <Styles styles={photo.styles} />
          </Box>
          <Box>
            <Likes photo={photo} />
          </Box>
          <Box>
            <Comments photo={photo} />
          </Box>
        </Columns.Column>
      </Columns>
    </>
  )
}

const CustomPhoto = connectHits(PhotoComponent)

const Tattoo = ({ id }) => {
  const searchClient = useAlgolia()

  // What if there is no id?

  return (
    <Layout>
      <Section>
        <Container>
          <InstantSearch indexName={INDEX_PHOTOS} searchClient={searchClient}>
            <Configure filters={`objectID:"${id}"`} hitsPerPage={1} />
            <CustomPhoto />
          </InstantSearch>
        </Container>
      </Section>
    </Layout>
  )
}

export default Tattoo
